<template>
	<div class="scrollbar-wrapper" style="height: 100%;">
		<el-scrollbar style="height: 100%;" v-if="activePageUUID">
			<template v-if="activePage.elements.length">
				<div class="page-item"
					v-for="item in activePage.elements"
					:key="item.uuid"
					:class="{active: activeElementUUID === item.uuid}"
					@click="handleElementClick(item.uuid)"
					@dragover.prevent="handleDragOver($event, item)"
					@dragstart="handleDragStart($event, item)"
					@dragenter="handleDragEnter($event, item)"
					@dragend="handleDragEnd($event, item)"
					draggable>
					<div class="page-item-btn-wrapper" v-if="item.config">
						<el-tooltip effect="dark" :content="item.config.hide?'显示图层':'隐藏图层'" placement="top">
							<div class="btn-item" @click.stop.prevent="onOpt({action: 'hide', element: item})"><i class="el-icon-view"></i></div>
						</el-tooltip>
						<el-tooltip effect="dark" :content="item.config.lock?'解锁图层':'锁定图层'" placement="top">
							<div class="btn-item" @click.stop.prevent="onOpt({action: 'lock', element: item})"><i :class="item.config.lock?'el-icon-lock':'el-icon-unlock'"></i></div>
						</el-tooltip>
					</div>
					<el-input size="mini" ref="renameinput" v-model.trim="item.name" placeholder="请输入名称" @blur="() => { item.config.edit = 0; if (!item.name) { item.name = '未命名' } }" v-if="item.config.edit" clearable></el-input>
					<div class="title" @dblclick="rename(item)" v-else>[{{elements[item.elName]}}]{{item.name}}</div>
					<el-dropdown @command="onOpt">
						<div style="padding: 6px;"><i class="el-icon-more-outline"></i></div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item icon="el-icon-document-copy" :command="{action: 'copy', element: item}">复制图层</el-dropdown-item>
							<el-dropdown-item icon="el-icon-delete" :command="{action: 'delete', element: item}">删除图层</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</template>
			<p style="color: #666; text-align: center;" v-else>暂无图层</p>
		</el-scrollbar>
		<p style="color: #666; text-align: center;" v-else>请选择一个页面</p>
	</div>
</template>

<script>
	import project from '@/utils/h5-model';
	import { _qk_register_components_object } from '@/plugins/index';
	import { mapState, mapGetters } from 'vuex';

	export default {
		data() {
			return {
				getCommonStyle: project.getCommonStyle,
				scale: 100
			}
		},
		computed: {
			...mapState({
				theme: state => state.theme,
				projectData: state => state.editor.projectData,
				activePageUUID: state => state.editor.activePageUUID,
				activeElementUUID: state => state.editor.activeElementUUID
			}),
			...mapGetters([
				'activePage',
			]),
			elements () {
				var elements = [];
				for (var i in  _qk_register_components_object) {
					elements[_qk_register_components_object[i].name] = _qk_register_components_object[i].data().title;
				}
				return elements;
			}
		},
		methods: {
			handleElementClick(uuid) {
				this.$store.dispatch('setActiveElementUUID', uuid);
			},
			handleDragStart (e, i) {
				console.log(e);
				this.dragging = i;
			},
			handleDragEnd (e, i) {
				console.log(e, i);
				this.dragging = null;
			},
			handleDragOver (e) {
				e.dataTransfer.dropEffect = 'move';
			},
			handleDragEnter (e, i) {
				e.dataTransfer.effectAllowed = 'move';
				if (i === this.dragging) return false;
				const elements = [...this.activePage.elements];
				const src = elements.indexOf(this.dragging);
				const dst = elements.indexOf(i);
				elements.splice(dst, 0, ...elements.splice(src, 1));
				for (var j = 0; j < elements.length; j++) {
					elements[j].commonStyle.zIndex = j+1;
				}
				this.activePage.elements = elements;
			},
			rename (item) {
				item.config = { ...item.config, edit: 1 };
				this.$nextTick(() => {
					this.$refs['renameinput'][0].$refs.input.focus();
				})
			},
			onOpt (o) {
				const { config } = o.element;
				switch (o.action) {
					case 'hide':
						o.element.config = { ...config, hide: config.hide ? 0 : 1 }
						break;
					case 'lock':
						o.element.config = { ...config, lock: config.lock ? 0 : 1 }
						break;
					case 'copy':
						this.$store.dispatch('copyElement', o.element)
						break;
					case 'delete':
							this.$confirm('此操作删除该元素无法恢复, 是否继续?', '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								this.$store.dispatch('deleteElement', o.element.uuid)
							});
						break;
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page-item {
		width: 100%;
		color: #666666;
		cursor: pointer;
		display: flex;
		padding: 10px 0;
		overflow: hidden;
		position: relative;
		background: white;
		transition: all 0.28s;
		align-items: center;
		border-bottom: 1px solid #e6ebed;

		&.active {
			color: #409eff;
			background-color: #eee;
		}

		.title {
			flex: 1;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.page-item-btn-wrapper {
			display: flex;
			margin-right: 5px;

			.btn-item {
				padding: 4px 4px;
				font-size: 16px;
				transition: all 0.28s;
				&:hover {
					color: $primary;
					transform: scale(1.05);
				}
			}
		}
	}
</style>
