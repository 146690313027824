<template>
	<el-descriptions size="mini" direction="vertical" :column="1">
		<el-descriptions-item label="图片">
			<upload-image :url.sync="tempValue" :params="{type: 'article_thumb'}"/>
		</el-descriptions-item>
	</el-descriptions>
</template>

<script>
	import uploadImage from '@/components/upload-image';
	export default {
		name: "attr-qk-imageSrc",
		props: {
			imageSrc: String
		},
		components: {
			uploadImage
		},
		data () {
			return {
				tempValue: ''
			}
		},
		mounted () {
			this.tempValue = this.imageSrc;
		},
		watch: {
			imageSrc (val) {
				this.tempValue = val;
			},
			tempValue () {
				this.$emit('update:imageSrc', this.tempValue);
			}
		}
	}
</script>

<style scoped>

</style>
