<template>
	<el-descriptions size="mini" direction="vertical" :column="1">
		<el-descriptions-item label="文本内容">
			<el-input type="textarea" placeholder="请输入文本内容" v-model="tempText" :rows="2"></el-input>
		</el-descriptions-item>
	</el-descriptions>
</template>

<script>
	export default {
		name: "attr-qk-text",
		props: {
			text: String
		},
		data() {
			return {
				tempText: ''
			}
		},
		mounted() {
			this.tempText = this.text;
		},
		watch: {
			text() {
				this.tempText = this.text;
			},
			tempText() {
				this.$emit('update:text', this.tempText)
			}
		}
	}
</script>

<style scoped>

</style>
