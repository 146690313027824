<!--矩形边框-->
<template>
	<div class="qk-rectangle-border"></div>
</template>

<script>
	export default {
		name: 'QkRectangleBorder',
		data () {
			return {
				title: '矩形边框',
				icon: 'iconfont iconjuxing',
				category: '基础组件',
				defaultStyle: {
					width: 120,
					height: 100,
					borderColor: "#999999",
					borderStyle: 'solid',
					borderWidth: 2
				}
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>
