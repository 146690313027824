<template>
	<div class="scrollbar-wrapper" style="height: 100%; padding-bottom: 60px">
		<el-scrollbar style="height: 100%;">
			<template v-if="projectData[mode] && projectData[mode].length">
				<div class="page-item"
					v-for="(item, index) in projectData[mode]"
					:key="item.uuid"
					:draggable="draggable"
					:class="{active: activePageUUID === item.uuid}"
					@click="changeActivePage(item)"
					@dragover.prevent="handleDragOver($event, item)"
					@dragstart="handleDragStart($event, item)"
					@dragenter="handleDragEnter($event, item)"
					@dragend="handleDragEnd($event, item)">
					<div class="page-item-index">{{index + 1}}</div>
					<div style="width: 100px; height: 140px; border: 1px solid #f1f1f1; overflow: hidden;">
						<div :style="getCommonStyle({...item.commonStyle, position: 'relative', width: '340', height: '620'}, 100/340)">
							<div
								v-for="element in item.elements"
								:key="element.uuid"
								:uuid="element.uuid"
								:defaultStyle="element.commonStyle"
								:style="getCommonStyle({
									top: element.commonStyle.top,
									left: element.commonStyle.left,
									width: element.commonStyle.width,
									height: element.commonStyle.height,
									position: element.commonStyle.position
								}, 100/340)">
								<component :style="getCommonStyle({...element.commonStyle, top: 0, left: 0}, 100/340)" :is="element.elName"></component>
							</div>
						</div>
					</div>
					<div class="page-item-btn-wrapper">
						<el-tooltip effect="dark" :content="item.config.hide?'显示页面':'隐藏页面'" placement="right" v-if="mode == 'pages'">
							<div class="btn-item" @click.stop.prevent="hidePage(item)"><i :class="item.config.hide?'el-icon-remove-outline':'el-icon-view'"></i></div>
						</el-tooltip>
						<el-tooltip effect="dark" content="复制页面" placement="right">
							<div class="btn-item" @click.stop.prevent="copyPage(item)"><i class="el-icon-document-copy"></i></div>
						</el-tooltip>
						<el-tooltip effect="dark" content="添加页面" placement="right">
							<div class="btn-item" @click.stop.prevent="addPage(item)"><i class="el-icon-document-add"></i></div>
						</el-tooltip>
						<el-tooltip effect="dark" content="删除页面" placement="right">
							<div class="btn-item" @click.stop.prevent="deletePage(item)"><i class="el-icon-delete"></i></div>
						</el-tooltip>
					</div>
				</div>
			</template>
			<p style="color: #666; text-align: center;" v-else>暂无数据</p>
		</el-scrollbar>
		<div class="bottom-btn-wrapper">
			<el-button type="primary" size="small"  @click="addPage()">新增页面</el-button>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import editorProjectConfig from '@/utils/h5-model';
	import {_qk_register_components_object} from '@/plugins/index';

	export default {
		components: {
			..._qk_register_components_object
		},
		props: {
			mode: {
				type: String,
				default: 'pages'
			},
			draggable: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			...mapState({
				projectData: state => state.editor.projectData,
				activePageUUID: state => state.editor.activePageUUID
			})
		},
		data() {
			return {
				getCommonStyle: editorProjectConfig.getCommonStyle,
			}
		},
		methods: {
			changeActivePage(item) {
				this.$store.dispatch('setEditMode', this.mode)
				this.$store.dispatch('setActivePageUUID', item.uuid)
			},
			hidePage(item) {
				item.config = { ...item.config, hide: item.config.hide ? 0 : 1 }
			},
			copyPage(item) {
				this.$store.dispatch('copyPage', item.uuid)
			},
			addPage(item) {
				this.$store.dispatch('setEditMode', this.mode)
				this.$store.dispatch('addPage', item ? item.uuid : '')
			},
			deletePage(item) {
				if (this.mode == 'pages' && this.projectData[this.mode].length == 1) return this.$message.error('无法删除最后一个页面！');
				this.$confirm('此操作删除该页面无法恢复, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$store.dispatch('deletePage', item.uuid)
				});
			},
			handleDragStart (e, i) {
				console.log(e);
				this.dragging = i;
			},
			handleDragEnd (e, i) {
				console.log(e, i);
				this.dragging = null;
			},
			handleDragOver (e) {
				e.dataTransfer.dropEffect = 'move';
			},
			handleDragEnter (e, i) {
				e.dataTransfer.effectAllowed = 'move';
				if (i === this.dragging) return false;
				const pages = [...this.projectData[this.mode]];
				const src = pages.indexOf(this.dragging);
				const dst = pages.indexOf(i);
				pages.splice(dst, 0, ...pages.splice(src, 1));
				this.projectData[this.mode] = pages;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page-item {
		width: 100%;
		color: #666666;
		padding: 10px;
		display: flex;
		cursor: pointer;
		overflow: hidden;
		transition: all 0.28s;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #e6ebed;
		background: white;
		
		&.active {
			color: #111;
			background-color: #eee;
			.page-item-index{
				background: $primary;
				color: white;
			}
		}
		.page-item-index {
			width: 24px;
			height: 24px;
			color: #fff;
			display: inline-block;
			line-height: 24px;
			text-align: center;
			border-radius: 12px;
			background-color: #ccc;
		}
		.page-item-btn-wrapper {
			display: flex;
			padding-left: 10px;
			flex-direction: column;
			border-left: 1px solid #fff;

			.btn-item {
				padding: 6px 6px;
				font-size: 18px;
				transition: all 0.28s;
				&:hover {
					color: $primary;
					transform: scale(1.05);
				}
			}
		}
	}
	.bottom-btn-wrapper {
		width: 100%;
		height: 60px;
		padding-top: 12px;
		text-align: center;
	}
</style>
