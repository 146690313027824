<!--button.vue-->
<template>
	<div class="qk-button">{{text}}</div>
</template>

<script>
	export default {
		name: 'QkButton', // 这个名字很重要，它就是未来的标签名
		props: {
			text: {
				type: String,
				default: '按 钮'
			}
		},
		data () {
			return {
				title: '按钮',
				icon: 'iconfont iconanniuzu',
				category: '基础组件',
				defaultStyle: {
					width: 140,
					height: 40,
					paddingTop: 10,
					paddingBottom: 10,
					borderColor: "#999999",
					borderStyle: 'solid',
					borderWidth: 1,
					borderRadius: 4
				}
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>
