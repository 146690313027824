<template>
	<div :style="'display: flex; height: 100%; position: relative; height: '+(theme.height-160)+'px'" v-loading="loading">
		<!-- 作品编辑区域 -->
		<div style="width: 240px; padding: 0;">
			<el-tabs class="dddd" style="background-color: #fff;" value="page" stretch>
				<el-tab-pane name="page" label="页面">
					<page-manage draggable></page-manage>
				</el-tab-pane>
				<el-tab-pane name="dialog" label="弹窗">
					<page-manage mode="dialogs"></page-manage>
				</el-tab-pane>
				<el-tab-pane name="element" label="图层">
					<element-manage></element-manage>
				</el-tab-pane>
				<el-tab-pane name="component" label="组件">
					<component-libs></component-libs>
				</el-tab-pane>
				<el-tab-pane name="template" label="模板">
					<p style="color: #666; text-align: center;">待开发</p>
				</el-tab-pane>
			</el-tabs>
		</div>
		<!-- 页面编辑区域 -->
		<div style="width: calc(100% - 640px); background: #f0f2f5; position: relative;">
			<editor-pan @showPreview="previewFn"></editor-pan>
		</div>
		<!-- 属性编辑区域 -->
		<div class="scrollbar-wrapper" style="width: 400px; padding: 0 0 0 10px;">
			<element-edit v-if="activeElementUUID"></element-edit>
			<page-edit v-else></page-edit>
		</div>
		<!-- 预览 -->
		<preview-page :showDrawer="showPreview" :id="projectData.id" @closePreview="showPreview = false" @publish="publishFn"></preview-page>
		<!-- 我的图片 -->
		<image-libs></image-libs>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	import editorProjectConfig from '@/utils/h5-model';

	import previewPage from './components/preview';
	import pageEdit from './components/page-edit';
	import elementEdit from './components/element-edit';
	import editorPan from './components/editor-panel';
	import pageManage from './components/page-manage';
	import elementManage from './components/element-manage';
	import componentLibs from './components/component-libs';
	import imageLibs from '@/components/image-libs';

	export default {
		components: {
			pageManage,
			elementManage,
			componentLibs,
			imageLibs,
			editorPan,
			pageEdit,
			elementEdit,
			previewPage
		},
		data() {
			return {
				getCommonStyle: editorProjectConfig.getCommonStyle,
				loading: false,
				showPreview: false
			}
		},
		computed: {
			...mapState({
				theme: state => state.theme,
				projectData: state => state.editor.projectData,
				activeElementUUID: state => state.editor.activeElementUUID
			})
		},
		created() {
			this.$store.dispatch('setPrjectData');
			this.initPageData(this.$route.query.id);
		},
		methods: {
			async initPageData(id) {
				this.loading = true;
				const res = await this.$http.get(this.$api.URI_H5PAGES, {params: {action: 'create', id: id}, headers: {loading: true}});
				const { code, msg, result } = res.data;
				this.loading = false;
				if (code != 0) return this.$message.error(msg);
				if (!result.bgm) {
					result.bgm = editorProjectConfig.bgmConfig;
				}
				if (!result.indicator) {
					result.indicator = editorProjectConfig.indicatorConfig;
				}
				if (!result.flip) {
					result.flip = editorProjectConfig.flipConfig;
				}
				this.$store.dispatch('setPrjectData', {...result});
			},
			async publishFn() {
				// 提交数据再预览
				let data = {...this.projectData};
				// data.isPublish = true;
				this.$http.post(this.$api.URI_H5PAGES, data).then(() => {
					this.$message.success('已成功保存并发布!');
					this.showPreview = false;
					// this.$router.push({name: 'pageList'})
				})
			},
			async previewFn() {
				// 提交数据再预览
				this.$http.post(this.$api.URI_H5PAGES, {...this.projectData}).then(() => {
					this.showPreview = true;
				})
			}
		}
	}
</script>

<style lang="scss">
	.dddd,
	.dddd .el-tab-pane {
		height: 100%;
	}
	.dddd .el-tabs__content {
		height: calc(100% - 55px);
	}
	.dddd .el-tabs__item {
		padding: 0;
	}
</style>
