<!--test.vue-->
<template>
	<div class="qk-text" @dblclick="contenteditable = true" @blur="changeInput" :contenteditable="contenteditable">{{text}}</div>
</template>

<script>
	export default {
		name: 'QkText',
		props: {
			text: {
				type: String,
				default: '这是一段文字'
			}
		},
		data () {
			return {
				title: '文字',
				icon: 'iconfont iconwenben',
				category: '基础组件',
				contenteditable: false,
				defaultStyle: {
					height: 40
				}
			}
		},
		methods: {
			changeInput (e) {
				this.contenteditable = false;
				this.$emit('update:text', e.target.innerHTML)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.qk-text {
		outline: none;
	}
</style>
