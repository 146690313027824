<template>
	<div class="editor-pane" @click="handleClickCanvas" ref="editorPane">
		<div class="components-contrl-bar">
			<el-tooltip effect="dark" content="保存预览" placement="right">
				<div class="button-item" @click="showPreview"><i class="iconfont iconbaocun"></i></div>
			</el-tooltip>
			<el-tooltip effect="dark" content="撤销" placement="right">
				<div class="button-item" :class="{disabled: !canUndo}" @click="editorUndo"><i class="iconfont iconundo1"></i></div>
			</el-tooltip>
			<el-tooltip effect="dark" content="重做" placement="right">
				<div class="button-item" :class="{disabled: !canRedo}" @click="editorRedo"><i class="iconfont iconredo1"></i></div>
			</el-tooltip>
			<el-tooltip effect="dark" content="放大" placement="right">
				<div class="button-item" @click="updateScale('plus')"><i class="el-icon-zoom-in"></i></div>
			</el-tooltip>
			<el-input size="mini" class="scale-input" v-model="scale" placeholder="请输入内容"></el-input>
			<el-tooltip effect="dark" content="缩小" placement="right">
				<div class="button-item" @click="updateScale('reduce')"><i class="el-icon-zoom-out"></i></div>
			</el-tooltip>
		</div>
		<div class="editor-pane-inner">
			<div
				class="editor-main"
				:style="getCommonStyle({
					width: activePage.config.long?(activePage.config.direction == 1?(activePage.commonStyle.width):width):width,
					height: activePage.config.long?(activePage.config.direction == 0?(activePage.commonStyle.height):height):height
				}, scale/100)">
				<div class="page-preview-wrapper" ref="canvas-panel" id="canvas-panel" :style="getCommonStyle({...activePage.commonStyle}, scale/100)">
					<template v-if="editMode == 'pages' && (!activePage.config.long || activePage.config.direction == 1)">
						<div class="phone-screen" :style="phoneScreenStyle(505)">
							<div class="tips">1010px<br>常规屏</div>
							<div style="border-top: 2px dashed rgb(194, 194, 194);"></div>
						</div>
						<div class="phone-screen" :style="phoneScreenStyle(height)">
							<div class="tips">{{height*2}}px<br>全面屏</div>
							<div style="border-top: 2px dashed rgb(194, 194, 194);"></div>
						</div>
					</template>
					<edit-shape
						v-for="item in activePage.elements"
						:key="item.uuid"
						:uuid="item.uuid"
						:config="item.config"
						:active="item.uuid === activeElementUUID"
						:defaultStyle="item.commonStyle"
						:style="getCommonStyle({
							top: item.commonStyle.top,
							left: item.commonStyle.left,
							width: item.commonStyle.width,
							height: item.commonStyle.height,
							position: item.commonStyle.position
						}, scale/100)"
						@handleElementClick="handleElementClick(item.uuid)"
						@resize="handleElementResize">
						<component :style="getCommonStyle({...item.commonStyle, top: 0, left: 0}, scale/100)" :is="item.elName" class="element-on-edit-pane" v-bind="item.propsValue"></component>
					</edit-shape>
				</div>
				<div class="page-wrapper-mask"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import editShape from '@/components/edit-shape';
	import editorProjectConfig from '@/utils/h5-model';
	import { mapState, mapGetters, mapActions } from 'vuex';
	import { _qk_register_components_object } from '@/plugins/index';

	export default {
		components: {
			..._qk_register_components_object,
			[editShape.name]: editShape
		},
		props: {
			width: {
				type: Number,
				default: 340
			},
			height: {
				type: Number,
				default: 620
			}
		},
		data() {
			return {
				getCommonStyle: editorProjectConfig.getCommonStyle,
				phoneScreenStyle: (h) => {
					return {
						top: (h*(this.scale/100))+'px',
						width: ((this.activePage.config.direction == 1 ? this.activePage.commonStyle.width : this.width)+50)+'px',
						transform: 'scale('+ (this.scale/100) +')'
					}
				},
				scale: 100
			}
		},
		computed: {
			...mapState({
				theme: state => state.theme,
				editMode: state => state.editor.editMode,
				projectData: state => state.editor.projectData,
				activePageUUID: state => state.editor.activePageUUID,
				activeElementUUID: state => state.editor.activeElementUUID
			}),
			...mapGetters([
				'currentPageIndex',
				'activeElementIndex',
				'activeElement',
				'activePage',
				'canUndo',
				'canRedo'
			])
		},
		mounted() {
			document.addEventListener('keydown', (e) => {
				if (this.activeElementUUID) {
					const { commonStyle, config } = this.activeElement;
					switch (e.key) {
						case 'ArrowUp':
							if (config && !config.lock) {
								this.activeElement.commonStyle = { ...commonStyle, top: --commonStyle.top }
							}
						break;
						case 'ArrowDown':
							if (config && !config.lock) {
								this.activeElement.commonStyle = { ...commonStyle, top: ++commonStyle.top }
							}
						break;
						case 'ArrowLeft':
							if (config && !config.lock) {
								this.activeElement.commonStyle = { ...commonStyle, left: --commonStyle.left }
							}
						break;
						case 'ArrowRight':
							if (config && !config.lock) {
								this.activeElement.commonStyle = { ...commonStyle, left: ++commonStyle.left }
							}
						break;
						case 'Delete':
							this.$confirm('此操作删除该元素无法恢复, 是否继续?', '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								this.$store.dispatch('elementCommand', 'delete')
							});
						break;
					}
					this.$store.dispatch('addHistoryCache');
				}
			})
		},
		methods: {
			...mapActions([
				'editorUndo',
				'editorRedo'
			]),
			showPreview(){
				this.$emit('showPreview')
			},
			updateScale(type, value) {
				if (type === 'plus') {
					this.scale = this.scale + (value || 10) > 200 ? 200 : this.scale + (value || 10)
				} else if (type === 'reduce') {
					this.scale = this.scale - (value || 10) > 50 ? this.scale - (value || 10) : 50;
				} else if (type === 'reset') {
					this.scale = value || 100;
				}
			},
			handleElementClick(uuid) {
				this.$store.dispatch('setActiveElementUUID', uuid);
			},
			handleElementResize(pos) {
				if (!pos) {
					this.$store.dispatch('addHistoryCache');
					return;
				}
				let element = this.projectData[this.editMode][this.currentPageIndex].elements[this.activeElementIndex];
				element.commonStyle.left = pos.left;
				element.commonStyle = { ...pos };
			},
			handleClickCanvas(e) {
				if (!e.target.classList.contains('element-on-edit-pane') && !e.target.classList.contains('menu-item-on-edit-panel')) {
					this.$store.dispatch('setActiveElementUUID', '');
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.editor-pane {
		height: 100%;
		width: 100%;
		border: 1px solid #f5f5f5;
		border-width: 0 1px;
		background-image: linear-gradient(45deg, #f5f5f5 25%, transparent 0, transparent 75%, #f5f5f5 0), linear-gradient(45deg, #f5f5f5 25%, transparent 0, transparent 75%, #f5f5f5 0);
		background-position: 0 0, 13px 13px;
		background-size: 26px 26px;
		display: flex;
		justify-content: center;
	}

	.editor-pane-inner {
		height: 100%;
		width: 100%;
		overflow: auto;
		padding: 20px;
	}

	.editor-main {
		margin: 0 auto;
		position: relative;
		background: white;
		border-radius: 3px;
		box-shadow: 0 3px 10px #dcdcdc;
		transform-origin: center top;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZAgMAAAC5h23wAAAAAXNSR0IB2cksfwAAAAlQTFRF9fX18PDwAAAABQ8/pgAAAAN0Uk5T/yIA41y2EwAAABhJREFUeJxjYIAC0VAQcGCQWgUCDUONBgDH8Fwzu33LswAAAABJRU5ErkJggg==");
	}


	.components-contrl-bar {
		background-color: #fff;
		.button-item {
			display: block;
			padding: 12px;
			cursor: pointer;
			text-align: center;
			vertical-align: bottom;
			font-size: 14px;
			transition: color 0.28s, transform 0.28s;
			user-select: none;
			&.scale-wrappper {
				width: 60px;
				&:hover {
					color: inherit;
					transform: none;
				}
				& > .scale-btn {
					display: block;
					vertical-align: middle;
					font-size: 20px;
					transition: all 0.28s;
					&:hover {
						color: $primary;
					}
				}
			}
			&:hover {
				color: $primary;
				background: rgba(37, 165, 137, 0.09);
			}
			&.disabled {
				cursor: not-allowed;
				color: $gray !important;
			}
			& > i {
				font-size: 18px;
				display: inline-block;
				transition: all 0.28s;
			}
			& > p {
				font-size: 14px;
			}
		}
	}

	.page-preview-wrapper {
		height: 100%;
		position: relative;
	}

	.page-wrapper-mask {
		height: 100%;
		width: 100%;
		z-index: 998;
		position: absolute;
		left: 0;
		top: 0;
		pointer-events: none;
		outline: rgba(236, 238, 240, 0.3) solid 10000px;
	}

	.element-on-edit-pane {
		height: 100%;
		width: 100%;
		overflow: hidden;
	}

	.phone-screen {
		position: absolute;
		z-index: 1;
		left: 0;
		transform-origin: left top;
		.tips {
			color: #c2c2c2;
			font-size: 12px;
			text-align: center;
			position: absolute;
			right: 0;
			bottom: 10px;
		}
	}
</style>
<style lang="scss">
	.scale-input {
		width: 40px;
	}
	.scale-input input {
		border: none;
		text-align: center;
		padding: 0;
	}
</style>
