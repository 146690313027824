<template>
	<el-scrollbar style="height: calc(100% - 55px);">
		<div style="padding-right: 10px;">
			<el-descriptions title="组件属性" :labelStyle="{marginRight: 0}" :column="1" :colon="false">
				<el-descriptions-item v-if="currentElementProps.length">
					<component v-for="item in currentElementProps" v-bind.sync="activeElement.propsValue" :key="item" :is="'attr-qk-' + item"/>
				</el-descriptions-item>
			</el-descriptions>

			<el-descriptions title="组件样式" :labelStyle="{marginRight: 0}" :column="1" :colon="false">
				<el-descriptions-item>
					<el-collapse style="width: 100%" accordion>
						<el-collapse-item title="尺寸与位置" name="1">
							<el-descriptions :column="1">
								<el-descriptions-item label="快捷定位">
									<div class="align-type-item" v-for="item in alignTypeList" :key="item.type" @click="changeAlignType(item.type)">
										<el-tooltip effect="dark" :content="item.title" placement="bottom"><i :class="[item.icon]"></i></el-tooltip>
									</div>
								</el-descriptions-item>
								<el-descriptions-item label="尺寸(W,H)">
									<el-input-number size="mini" v-model="activeElement.commonStyle.width" controls-position="right" :min="0" @change="(c, o) => { if (activeElement.fixed_aspect_ratio) { activeElement.commonStyle.height += c-o }}" class="input-wr"/>
									<el-input-number size="mini" v-model="activeElement.commonStyle.height" controls-position="right" :min="0" @change="(c, o) => { if (activeElement.fixed_aspect_ratio) { activeElement.commonStyle.width += c-o }}" class="input-wr"/>
									<el-checkbox v-model="activeElement.fixed_aspect_ratio">固定比例</el-checkbox>
								</el-descriptions-item>
								<el-descriptions-item label="快捷(resize)">
									<div class="align-type-item clearFlex" @click="handleResizeClick('wh')">
										<el-tooltip effect="dark" content="满屏" placement="bottom"><i class="iconfont iconquanping"></i></el-tooltip>
									</div>
									<div class="align-type-item clearFlex" @click="handleResizeClick('w')">
										<el-tooltip effect="dark" content="宽100%" placement="bottom"><i class="iconfont iconcolumn-width"></i></el-tooltip>
									</div>
									<div class="align-type-item clearFlex" @click="handleResizeClick('h')">
										<el-tooltip effect="dark" content="高100%" placement="bottom"><i class="iconfont iconcolum-height"></i></el-tooltip>
									</div>
								</el-descriptions-item>
								<el-descriptions-item label="位置(X,Y)">
									<el-input-number size="mini" v-model="activeElement.commonStyle.top" controls-position="right" class="input-wr"/>
									<el-input-number size="mini" v-model="activeElement.commonStyle.left" controls-position="right" class="input-wr"/>
								</el-descriptions-item>
								<el-descriptions-item label="旋转">
									<el-slider style="width: 100%;" v-model="activeElement.commonStyle.rotate" @change="throttleAddHistory" show-input :min="-180" :max="180" :marks="{0:'',90:'', '-90':''}" input-size="mini"></el-slider>
								</el-descriptions-item>
							</el-descriptions>
						</el-collapse-item>
						<el-collapse-item title="边框边距" name="2">
							<el-descriptions :column="1">
								<el-descriptions-item label="边框尺寸">
									<el-input-number size="mini" v-model="activeElement.commonStyle.borderWidth" @change="throttleAddHistory" controls-position="right" :min="0" class="input-wr"/>
								</el-descriptions-item>
								<el-descriptions-item label="边框类型">
									<el-select v-model="activeElement.commonStyle.borderStyle" @change="throttleAddHistory" size="mini" class="input-wr">
										<el-option v-for="item in borderStyleList" :key="item.value" :label="item.label" :value="item.value"/>
									</el-select>
								</el-descriptions-item>
								<el-descriptions-item label="边框颜色">
									<el-color-picker size="mini" @change="throttleAddHistory" v-model="activeElement.commonStyle.borderColor"></el-color-picker>
								</el-descriptions-item>
								<el-descriptions-item label="边框圆弧">
									<el-input-number size="mini" @change="throttleAddHistory" v-model="activeElement.commonStyle.borderRadius" controls-position="right" :min="0" class="input-wr"/>
								</el-descriptions-item>
								<el-descriptions-item label="上下边距">
									<el-input-number size="mini" @change="throttleAddHistory" v-model="activeElement.commonStyle.paddingTop" controls-position="right" :min="0" class="input-wr"/>
								</el-descriptions-item>
								<el-descriptions-item label="左右边距">
									<el-input-number size="mini" @change="throttleAddHistory" v-model="activeElement.commonStyle.paddingBottom" controls-position="right" :min="0" class="input-wr"/>
								</el-descriptions-item>
							</el-descriptions>
						</el-collapse-item>
						<el-collapse-item title="阴影样式" name="3">
							<el-descriptions :column="1">
								<el-descriptions-item label="阴影位置(X,Y)">
									<el-input-number size="mini" @change="boxShadowChange" v-model="boxShadow.h" controls-position="right" class="input-wr"/>
									<el-input-number size="mini" @change="boxShadowChange" v-model="boxShadow.v" controls-position="right" class="input-wr"/>
								</el-descriptions-item>
								<el-descriptions-item label="阴影位置(X,Y)">
									<el-input-number size="mini" @change="boxShadowChange" v-model="boxShadow.blur" controls-position="right" class="input-wr"/>
									<el-input-number size="mini" @change="boxShadowChange" v-model="boxShadow.spread" controls-position="right" class="input-wr"/>
								</el-descriptions-item>
								<el-descriptions-item label="阴影颜色">
									<el-color-picker size="mini" @change="boxShadowChange" v-model="boxShadow.color"></el-color-picker>
								</el-descriptions-item>
							</el-descriptions>
						</el-collapse-item>
						<el-collapse-item title="字体" name="4">
							<el-descriptions :column="1">
								<el-descriptions-item label="字体大小">
									<el-input-number size="mini" @change="throttleAddHistory" v-model="activeElement.commonStyle.fontSize" controls-position="right" :min="0" class="input-wr"/>
								</el-descriptions-item>
								<el-descriptions-item label="字体粗细">
									<el-input-number size="mini" @change="throttleAddHistory" v-model="activeElement.commonStyle.fontWeight" controls-position="right" :min="300" :step="100" :max="900" class="input-wr"/>
								</el-descriptions-item>
								<el-descriptions-item label="行间距">
									<el-input-number size="mini" @change="throttleAddHistory" v-model="activeElement.commonStyle.lineHeight" controls-position="right" :min="0" :step="0.1" class="input-wr"/>
								</el-descriptions-item>
								<el-descriptions-item label="字间距">
									<el-input-number size="mini" @change="throttleAddHistory" v-model="activeElement.commonStyle.letterSpacing" controls-position="right" :min="0" class="input-wr"/>
								</el-descriptions-item>
								<el-descriptions-item label="对齐方式">
									<div class="align-type-item clearFlex" @click="handleTextAlignClick('left')">
										<el-tooltip effect="dark" content="左对齐" placement="bottom"><i class="iconfont iconzuoduiqi1"></i></el-tooltip>
									</div>
									<div class="align-type-item clearFlex" @click="handleTextAlignClick('center')">
										<el-tooltip effect="dark" content="居中对齐" placement="bottom"><i class="iconfont iconjuzhongduiqi"></i></el-tooltip>
									</div>
									<div class="align-type-item clearFlex" @click="handleTextAlignClick('right')">
										<el-tooltip effect="dark" content="右对齐" placement="bottom"><i class="iconfont iconyouduiqi2"></i></el-tooltip>
									</div>
								</el-descriptions-item>
								<el-descriptions-item label="字体颜色">
									<el-color-picker size="mini" @change="throttleAddHistory" v-model="activeElement.commonStyle.color"></el-color-picker>
								</el-descriptions-item>
							</el-descriptions>
						</el-collapse-item>
						<el-collapse-item title="背景&&透明度" name="5">
							<el-descriptions :column="1">
								<el-descriptions-item label="背景颜色">
									<el-color-picker size="mini" :show-alpha="true" @change="throttleAddHistory" v-model="activeElement.commonStyle.backgroundColor"></el-color-picker>
								</el-descriptions-item>
								<el-descriptions-item label="背景图片">
									<upload-image :url.sync="activeElement.commonStyle.backgroundImage" :params="{type: 'article_thumb'}" @change="throttleAddHistory" library/>
								</el-descriptions-item>
								<el-descriptions-item label="透明度">
									<el-input-number size="mini" @change="throttleAddHistory" v-model="activeElement.commonStyle.opacity" controls-position="right" :min="0" :max="1" :step="0.1" class="input-wr"/>
								</el-descriptions-item>
							</el-descriptions>
						</el-collapse-item>
					</el-collapse>
				</el-descriptions-item>
			</el-descriptions>

			<el-descriptions title="组件动画" :labelStyle="{marginRight: 0}" :column="1" :colon="false">
				<div slot="extra">
					<el-button size="mini" icon="el-icon-caret-right" @click="runAnimate(undefined)">预览动画</el-button>
					<el-button size="mini" icon="el-icon-plus" type="primary" @click="addAnimate">添加动画</el-button>
				</div>
				<el-descriptions-item>
					<el-collapse style="width: 100%;" v-show="activeElement.animations && activeElement.animations.length" accordion>
						<el-collapse-item v-for="(item, index) in activeElement.animations" :key="index">
							<template slot="title">
								<span class="el-animate-title-name">动画 {{index+1}}</span>
								<div class="el-animate-title-type-wrapper">
									<span class="el-animate-title-type" @click.stop.prevent="handleShowChooseAnimatePanel(index)">{{item.type}}<i class="el-icon-caret-right size-mini"></i></span>
								</div>
								<span class="el-animate-title-btn" @click.stop.prevent="runAnimate(index)">
									<i class="el-icon-caret-right"></i>
								</span>
								<span class="el-animate-title-btn" @click.stop.prevent="handleDeleteAnimate">
									<i class="el-icon-delete"></i>
								</span>
							</template>
							<el-descriptions :column="1">
								<el-descriptions-item label="动画时长">
									<el-input-number size="mini" v-model="item.duration" controls-position="right" :min="0" :step="0.1" class="input-wr"/>
								</el-descriptions-item>
								<el-descriptions-item label="动画延迟">
									<el-input-number size="mini" v-model="item.delay" controls-position="right" :min="0" :step="0.1" class="input-wr"/>
								</el-descriptions-item>
								<el-descriptions-item label="循环次数">
									<el-input-number size="mini" v-model="item.interationCount" controls-position="right" class="input-wr"/>
									<el-checkbox v-model="item.infinite" label="infinite" size="mini" border>循环播放</el-checkbox>
								</el-descriptions-item>
							</el-descriptions>
						</el-collapse-item>
					</el-collapse>
				</el-descriptions-item>
			</el-descriptions>

			<el-descriptions title="组件事件" :labelStyle="{marginRight: 0}" :column="1" :colon="false">
				<el-dropdown slot="extra" @command="addEvent">
					<el-button type="primary" size="mini">添加事件<i class="el-icon-arrow-down el-icon--right"></i></el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item v-for="(item, index) in eventTypeList" :key="index" :command="item.value">{{item.label}}</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<el-descriptions-item>
					<el-collapse style="width: 100%;" v-show="activeElement.events && activeElement.events.length" accordion>
						<el-collapse-item v-for="(item, index) in activeElement.events" :key="index">
							<template slot="title">
								<span class="el-animate-title-name">事件 {{index + 1}}</span>
								<div class="el-animate-title-type-wrapper">
									<span class="el-animate-title-type">{{item.type | getLabelText(eventTypeList)}}</span>
								</div>
								<span class="el-animate-title-btn" @click.stop.prevent="deleteEvent(index)"><i class="el-icon-delete"></i></span>
							</template>
							<div class="el-animate-item">
								<div class="attr-item-edit-wrapper" v-show="item.type !== 'share'">
									<p class="attr-item-title">链接/接口url：</p>
									<div class="col-1  attr-item-edit-input">
										<el-input type="textarea" :rows="3" placeholder="请输入url" v-model="item.url" />
									</div>
								</div>
							</div>
						</el-collapse-item>
					</el-collapse>
				</el-descriptions-item>
			</el-descriptions>
		</div>

		<el-drawer title="动画库" direction="rtl" size="400px" :withHeader="false" :visible.sync="showAnimatePanel">
			<el-tabs v-model="activeName" stretch>
				<el-tab-pane v-for="item in animateCssDatas" :key="item.label" :label="item.label" :name="item.label">
					<el-scrollbar class="animate-choose-item">
						<div class="animate-choose-item-inner" v-for="(animate, index) in item.children" :key="index" @mouseover="hoverPreviewAnimate = animate.value" @mouseleave="hoverPreviewAnimate = ''" @click="handleChooseAnimate(animate)">
							<span class="animate-choose-image" :style="{backgroundPosition: `${item.bg_X}px ${item.bg_Y}px`}" :class="[hoverPreviewAnimate === animate.value && animate.value + ' animated']"></span>
							<p>{{animate.label}}</p>
						</div>
					</el-scrollbar>
				</el-tab-pane>
			</el-tabs>
		</el-drawer>
	</el-scrollbar>
</template>

<script>
	import {mapState, mapGetters} from 'vuex';
	import {ceil, subtract, divide, throttle} from 'lodash';
	import Bus from '@/eventBus/index.js';
	import attrComponents from './element-props/index.js'
	import animateCssData from '@/utils/animate-css-data.js';
	import uploadImage from '@/components/upload-image';

	export default {
		components: {
			uploadImage,
			...attrComponents
		},
		data() {
			return {
				animateCssDatas: animateCssData,
				activeName: '进入',
				showAnimatePanel: false,
				reSelectAnimateIndex: undefined,
				hoverPreviewAnimate: '',
				eventTypeList: [{
					label: '链接跳转',
					value: 'link'
				}, {
					label: '分享链接',
					value: 'share'
				}, {
					label: '表单提交',
					value: 'submitForm'
				}, {
					label: '拨打电话',
					value: 'callTask'
				}, {
					label: '打开弹窗',
					value: 'openDialog'
				}, {
					label: '隐藏页面',
					value: 'hiddenPages'
				}, {
					label: '显示页面',
					value: 'showPages'
				}],
				alignTypeList: [{
					title: '左对齐',
					icon: 'iconfont iconzuoduiqi',
					type: 'l'
				}, {
					title: '上对齐',
					icon: 'iconfont iconshangduiqi',
					type: 't'
				}, {
					title: '右对齐',
					icon: 'iconfont iconyouduiqi',
					type: 'r'
				}, {
					title: '下对齐',
					icon: 'iconfont iconxiaduiqi',
					type: 'b'
				}, {
					title: '垂直居中对齐',
					icon: 'iconfont iconchuizhijuzhongduiqi',
					type: 'tb'
				}, {
					title: '水平居中对齐',
					icon: 'iconfont iconshuipingjuzhongduiqi',
					type: 'lr'
				}],
				borderStyleList: [{
					label: '无',
					value: 'none'
				}, {
					label: '实线',
					value: 'solid'
				}, {
					label: '虚线',
					value: 'dashed'
				}, {
					label: '点状',
					value: 'dotted'
				}, {
					label: '双线',
					value: 'double'
				}],
				boxShadow: {
					h: 0,
					v: 0,
					blur: 0,
					spread: 0,
					color: '#000000'
				}
			}
		},
		computed: {
			...mapState({
				activeElementUUID: state => state.editor.activeElementUUID,
				activeAttrEditCollapse: state => state.editor.activeAttrEditCollapse
			}),
			...mapGetters([
				'activeElement',
			]),
			/**
			* 当前选中元素需要编辑得props 列表
			 */
			currentElementProps(){
				if (!this.activeElement.propsValue) return [];
				let keyList = Object.keys(this.activeElement.propsValue);
				let editPropsComponentNameList = Object.keys(attrComponents);
				// 过滤掉找不到对应attr props编辑组件的key
				keyList = keyList.filter(v => {
					return editPropsComponentNameList.includes('attr-qk-' + v);
				});
				return keyList;
			}
		},
		watch: {
			activeElementUUID() {
				// 设置boxShadow
				this.$nextTick(() => {
					this.initBoxShadowEdit()
				})
			},
			activeElement() {
				console.log(this.activeElement)
			}
		},
		created() {
			this.throttleAddHistory = throttle(this.addHistory, 3000)
		},
		mounted() {
			this.initBoxShadowEdit()
		},
		methods: {
			/**
			 * 添加事件
			 * @param type 事件名称
			 */
			addEvent(type) {
				this.$store.dispatch('addElementEvent', type)
			},
			/**
			 * 删除事件
			 * @param index
			 */
			deleteEvent(index) {
				this.$store.dispatch('deleteElementEvent', index)
			},
			/**
			 * 纪录一条历史纪录
			 * */
			addHistory() {
				// console.log('common style change addHistoryCache')
				this.$store.dispatch('addHistoryCache')
			},
			/**
			 *
			 * @param type
			 */
			changeAlignType(type) {
				let canvasW = this.$config.canvasH5Width
				let canvasH = this.$config.canvasH5Height
				let eleW = this.activeElement.commonStyle.width;
				let eleH = this.activeElement.commonStyle.height;


				switch (type) {
					case 't':
						this.activeElement.commonStyle.top = 0;
						break;
					case 'b':
						this.activeElement.commonStyle.top = subtract(canvasH - eleH);
						break;
					case 'l':
						this.activeElement.commonStyle.left = 0;
						break;
					case 'r':
						this.activeElement.commonStyle.left = subtract(canvasW - eleW);
						break;
					case 'tb':
						this.activeElement.commonStyle.top = ceil(divide(subtract(canvasH - eleH), 2), 2);
						break;
					case 'lr':
						this.activeElement.commonStyle.left = ceil(divide(subtract(canvasW - eleW), 2), 2);
						break;
				}
			},
			/**
			 * 初始化阴影编辑对象
			 */
			initBoxShadowEdit() {
				let boxShadow = this.activeElement.commonStyle.boxShadow;
				let boxShadowEditConfig = {
					h: 0,
					v: 0,
					blur: 0,
					spread: 0,
					color: '#000000'
				}
				if (!boxShadow || boxShadow === 'none') {
					this.boxShadow = boxShadowEditConfig
					return
				}
				let str = boxShadow.split(' ')

				boxShadowEditConfig = {
					h: parseInt(str[0].replace('px', '')),
					v: parseInt(str[1].replace('px', '')),
					blur: parseInt(str[2].replace('px', '')),
					spread: parseInt(str[3].replace('px', '')),
					color: str[4]
				}
				this.boxShadow = boxShadowEditConfig;
			},
			boxShadowChange() {
				let str = `${this.boxShadow.h}px ${this.boxShadow.v}px  ${this.boxShadow.blur}px  ${this.boxShadow.spread}px  ${this.boxShadow.color}`
				this.activeElement.commonStyle.boxShadow = str;
			},
			/**
			 * 字体样式设置对齐方式
			 * @param str
			 */
			handleTextAlignClick(str) {
				this.activeElement.commonStyle.textAlign = str;
			},
			/**
			 * 字体样式设置对齐方式
			 * @param str
			 */
			handleResizeClick(type) {
				if (type.includes('w')) {
					this.activeElement.commonStyle.left = 0;
					this.activeElement.commonStyle.width = this.$config.canvasH5Width;
				}
				if (type.includes('h')) {
					this.activeElement.commonStyle.top = 0;
					this.activeElement.commonStyle.height = this.$config.canvasH5Height;
				}
			},

			/**
			 * 选取animate
			 * @param item
			 */
			handleChooseAnimate(item) {
				this.showAnimatePanel = false;
				if (this.reSelectAnimateIndex === undefined) {
					this.$store.dispatch('addElementAnimate', item.value)
				} else {
					this.activeElement.animations[this.reSelectAnimateIndex].type = item.value;
					this.$store.dispatch('addHistoryCache')
				}
			},
			/**
			 * 删除动画
			 * @param index
			 */
			handleDeleteAnimate(index) {
				this.$store.dispatch('deleteElementAnimate', index)
			},
			addAnimate(showAnimatePanel = true) {
				this.showAnimatePanel = showAnimatePanel;
				this.reSelectAnimateIndex = undefined;
			},
			handleShowChooseAnimatePanel(index) {
				this.reSelectAnimateIndex = index;
				this.showAnimatePanel = true;
			},
			/**
			 * 执行此条动画效果
			 */
			runAnimate(index) {
				let animationData = index === undefined ? this.activeElement.animations : [this.activeElement.animations[index]]
				Bus.$emit('RUN_ANIMATIONS', this.activeElement.uuid, animationData)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.input-wr {
		width: 100px;
		margin-right: 10px;
	}

	.align-type-item {
		flex: 1;
		cursor: pointer;
		text-align: center;
		&.clearFlex {
			width: 42px;
			flex: none;
		}
		i {
			line-height: 1;
			display: inline-block;
			padding: 6px;
			border-radius: 4px;
			background: rgba(37, 165, 137, 0.08);
		}
		&:hover {
			i {
				color: white;
				background: $primary;
			}
		}
	}

	.el-animate-title-name {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		font-weight: bold;
		width: 55px;
	}
	.el-animate-title-type-wrapper {
		display: inline-block;
		width: 164px;
	}
	.el-animate-title-type {
		display: inline-block;
		vertical-align: middle;
		max-width: 140px;
		height: 24px;
		line-height: 1px;
		color: #333;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		background: #fafafa;
		border-radius: 60px;
		padding: 4px 16px;
		border: none;
		cursor: pointer;
		margin: 0 20px 0 10px;
		&:hover {
			color: white;
			background: $primary;
		}
	}
	.el-animate-title-btn {
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		line-height: 24px;
		font-size: 14px;
		color: #666;
		width: 24px;
		height: 24px;
		cursor: pointer;
		border-radius: 4px;
		background: rgba(37, 165, 137, 0.08);
		margin-left: 6px;
		&:hover {
			color: white;
			background: $primary;
		}
	}

	.animate-choose-item {
		height: 100%;
		.animate-choose-item-inner {
			display: inline-block;
			width: 25%;
			height: 72px;
			color: rgb(118, 131, 143);
			text-align: center;
			cursor: pointer;
			& > .animate-choose-image {
				display: inline-block;
				width: 40px;
				height: 40px;
				margin-bottom: 6px;
				background-image: url(../../../common/images/use-beb469.png)
			}
			p {
				font-size: 12px;
				line-height: 1;
			}
		}
	}
</style>
