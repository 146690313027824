<template>
	<el-scrollbar style="height: calc(100% - 55px);">
		<el-descriptions title="页面样式" :column="1">
			<el-checkbox slot="extra" size="mini" v-model="activePage.long" :false-label="0" :true-label="1" border>长图</el-checkbox>
			<template v-if="activePage.long">
				<el-descriptions-item label="滑动方向">
					<el-switch active-text="水平" inactive-text="垂直" :active-value="1" :inactive-value="0" v-model="activePage.direction"></el-switch>
				</el-descriptions-item>
				<el-descriptions-item label="滑动宽度" v-if="activePage.direction == 1">
					<el-input-number controls-position="right" placeholder="请输入宽度" size="mini" v-model="activePage.commonStyle.width" :min="100"/>
				</el-descriptions-item>
				<el-descriptions-item label="滑动高度" v-if="activePage.direction == 0">
					<el-input-number controls-position="right" placeholder="请输入高度" size="mini" v-model="activePage.commonStyle.height" :min="100"/>
				</el-descriptions-item>
			</template>
			<el-descriptions-item label="背景颜色">
				<el-color-picker size="mini" :show-alpha="true" v-model="activePage.commonStyle.backgroundColor"></el-color-picker>
			</el-descriptions-item>
			<el-descriptions-item label="背景图片">
				<upload-image :url.sync="activePage.commonStyle.backgroundImage" :params="{type: 'article_thumb'}" library/>
			</el-descriptions-item>
		</el-descriptions>
		<el-descriptions title="页面脚本" :column="1" direction="vertical" :colon="false">
			<p slot="extra" class="gray">JS脚本在编辑模式下无效果</p>
			<el-descriptions-item>
				<el-input type="textarea" placeholder="请输入代码" :rows="10" v-model="projectData.script"></el-input>
			</el-descriptions-item>
		</el-descriptions>
	</el-scrollbar>
</template>

<script>
	import {mapState, mapGetters} from 'vuex';
	import uploadImage from '@/components/upload-image';

	export default {
		components: {
			uploadImage
		},
		computed: {
			...mapState({
				projectData: state => state.editor.projectData
			}),
			...mapGetters([
				'activePage'
			])
		}
	}
</script>

<style lang="scss" scoped>

</style>
