<!--test.vue-->
<template>
	<div class="qk-iframe">
		<iframe :src="url" frameborder="0" style="background-color:transparent;width: 100%; min-height: 100%;"></iframe>
	</div>
</template>

<script>
	export default {
		name: 'QkIframe', // 这个名字很重要，它就是未来的标签名
		props: {
			url: {
				type: String,
				default: "http://m.baidu.com/"
			}
		},
		data() {
			return {
				title: '内嵌iframe',
				icon: 'iconfont iconiframetianjia',
				category: '基础组件',
				defaultStyle: {
					width: 300,
					height: 300,
					paddingTop: 10,
					paddingBottom: 10
				}
			}
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>

</style>
