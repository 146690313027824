<template>
	<div class="components-libs-wrapper scrollbar-wrapper">
		<el-scrollbar style="height: 100%;">
			<ul class="scrollbar-wrapper">
				<li v-for="(item, index) in componentsList" :key="index" class="clearfix paddingB15">
					<div class="components-libs-title">
						<p>{{item.title}}</p>
					</div>
					<div v-if="item.components && item.components.length">
						<div class="components-lib-item" v-for="(element,i) in item.components" :key="i" @click="handleClick(element)">
							<div class="lib-item-img"><i :class="[element.icon]"></i></div>
							<p class="lib-item-title">{{element.title}}</p>
						</div>
					</div>
					<div v-else>
						<p class="gray text-center paddingT20">待完善12...</p>
					</div>
				</li>
			</ul>
		</el-scrollbar>
	</div>
</template>

<script>
	import {camelCase} from 'lodash'
	import { _components_config, _qk_register_components_object } from '@/plugins/index';

	export default {
		name: "component-libs",
		data() {
			return {
				componentsList: _components_config
			}
		},
		methods: {
			handleClick(item) {
				let props = this.getComponentProps(item.elName);
				this.$store.dispatch('addElement', {...item, needProps: props})
			},
			getComponentProps(elName) {
				let elComponentData
				for (let key in _qk_register_components_object) {
					if (key.toLowerCase() === camelCase(elName).toLowerCase()) {
						elComponentData = _qk_register_components_object[key];
						break;
					}
				}
				if (!elComponentData) return {}
				let props = {}
				for (let key in elComponentData.props) {
					props[key] = [Object, Array].includes(elComponentData.props[key].type) ? elComponentData.props[key].default() : elComponentData.props[key].default
				}
				return props;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.components-libs-wrapper {
		user-select: none;
		height: 100%;
		position: relative;
	}
	
	.components-libs-title {
		margin-bottom: 12px;
	}

	.components-lib-item {
		color: #424242;
		text-align: center;
		background: #f4f4f4;
		width: 68px;
		float: left;
		padding: 6px 0;
		margin: 5px;
		border: 1px solid #dddddd;
		font-size: 12px;
		cursor: pointer;
		transition: All 0.3s ease-in-out;
		&:hover {
			background: #fff;
			border: 1px solid $primary;
			color: $primary;
		}
	}
</style>
